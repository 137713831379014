export default function Benefits() {
  return (
    <div className="benefits section px-8 py-4 mx-auto sm:max-w-xl md:py-16 md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:pt-0">
      <div className="grid gap-6 gap-y-10 md:grid-cols-2 lg:grid-cols-5">
        <div className="grid gap-6 md:grid-cols-2 md:col-span-2 lg:col-span-3">
          <div className="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-slate-200">
            <div className="flex items-center mb-1">
              <span className="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-white rounded bg-fuchsia-700">
                1
              </span>
              <p className="text-lg font-semibold sm:text-base">
                Development Research
              </p>
            </div>
            <p className="text-sm text-gray-900">
              We help businessses understand the impact, level of effort and costs associated with strategizing, iterating, and launching their web app ideas.
            </p>
          </div>
          <div className="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-fuchsia-200">
            <div className="flex items-center mb-1">
              <span className="flex items-center justify-center w-4 h-4 mr-2 text-xs font-semibold text-fuchsia-700 rounded shadow-md bg-white">
                2
              </span>
              <p className="text-lg font-semibold sm:text-base">
                Site-Reliability Maintenance
              </p>
            </div>
            <p className="text-sm text-gray-900">
              We offer flexible tiers of web development and maintenance packages that adjust to your business needs and budget.
            </p>
          </div>
          <div className="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-fuchsia-200">
            <div className="flex items-center mb-1">
              <span className="flex items-center justify-center w-4 h-4 mr-2 text-xs font-semibold text-fuchsia-700 rounded shadow-md bg-white">
                3
              </span>
              <p className="text-lg font-semibold sm:text-base">
                Performance &amp; Uptime Monitoring
              </p>
            </div>
            <p className="text-sm text-gray-900">
              We help monitor and troubleshoot our customers' apps to minimize financial and overall business impact.
            </p>
          </div>
          <div className="rounded lg:p-5 lg:transition lg:duration-300 lg:hover:bg-slate-200">
            <div className="flex items-center mb-1">
              <span className="flex items-center justify-center w-4 h-4 mr-2 text-xs font-medium text-teal-900 rounded md:text-white bg-fuchsia-700">
                4
              </span>
              <p className="text-lg font-semibold sm:text-base">Ideal for Startups</p>
            </div>
            <p className="text-sm text-gray-900">
              A well defined plan doesn't need to break the bank. Budget out your web app with only the specific features you want.
            </p>
          </div>
        </div>
        <div className="relative md:col-span-2 lg:col-span-2">
          <img
            className="inset-0 object-cover object-bottom w-full h-56 rounded shadow-lg lg:absolute lg:h-full"
            src="https://images.pexels.com/photos/3182759/pexels-photo-3182759.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
