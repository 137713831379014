import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  };

  static getDerivedStateFromError(error) {
    return { hasError: !!error };
  };

  render() {
    if (this.state.hasError) {
      return <h3>Something went wrong. Please try refreshing your page.</h3>;
    }
    return this.props.children;
  };
};

export default ErrorBoundary;
