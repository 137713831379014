import HeroSection from './HeroSection';
import Services from './WhatWeDo';
import Steps from './OurProcess';
import FAQ from './FAQ';
import WhyUs from './Benefits';
import ProjectTracker from './ProjectTracker';
import Feedback from './WhyUs';
import ContactUs from './ContactUs';
import 'styles/Home.scss';

export default function Home() {
  return (
    <article className='home section'>
      <HeroSection />
      <Services />
      <WhyUs />
      <Steps />
      <ProjectTracker />
      <Feedback />
      <FAQ />
      <ContactUs />
    </article>
  );
};
