import Header from 'layout/Header';
import Main from 'layout/Main';
import Footer from 'layout/Footer';
import ErrorBoundary from 'components/ErrorBoundary';
import 'styles/App.scss';

function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <Header />
        <Main />
        <Footer />
      </ErrorBoundary>
    </div>
  );
}

export default App;
