import { useState } from 'react';
import 'styles/FAQ.scss';

function Item({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium">{title}</p>
        <svg
          viewBox="0 0 24 24"
          className={`w-3 text-gray-600 transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
        >
          <polyline
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="2,7 12,17 22,7"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};

export default function FAQ() {
  return (
    <div className="px-8 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16 ">
      <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-fuchsia-700 sm:text-4xl md:mx-auto">
            <span className="relative block text-center">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-fuchsia-700 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="232db96b-4aa2-422f-9086-5a77996d1df1"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#232db96b-4aa2-422f-9086-5a77996d1df1)"
                  width="52"
                  height="24"
                />
              </svg>
              Questions You Might Have
            </span>
          </h2>
          <p className="text-base text-gray-700 md:text-lg ">
            If you have any other question, don't hesitate to{' '}
            <a href='#contact'>contact us below!</a>
          </p>
        </div>
        <div className="space-y-4 text-fuchsia-700">
          <Item title="Do we have a niche?">
            We don't! We are a team of very capable developers who transitioned from
            different industries and bring our expertise to the table. We have more recently
            worked with customers inside the retail, concrete-mixing and restaurant industries,
            but we would never limit our possibilities! Reach out to us if you feel like we can
            help you and/or your business!
          </Item>
          <Item title="What is our usual delivery time?">
            During this startup phase of our organization, our full team currently only works
            part-time. As our client-base grows and our projects scale, we will obtain more
            flexibility to offer very prompt delivery times. For the moment, you can expect
            an approximate of 20-30 hours of development per week for any given project at 
            a time. Estimations are always provided to more accurately gauge delivery times.
          </Item>
          <Item title="How do we handle payments?">
            At the moment, we are still in our first steps towards expanding our
            investments towards commodities that will ease services such as payment
            handling and communication with our customers. We currently accept payments
            through Zelle and Paypal.
          </Item>
          <Item title="Will we take over the world?">
            You bet.
          </Item>
        </div>
      </div>
    </div>
  );
};
