import 'styles/Services.scss';

export default function WhatWeDo() {
  return (
    <div className="px-8 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 lg:pt-14 text-center sm:text-left" id='what-we-do'>
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-fuchsia-700 sm:text-4xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-fuchsia-700 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="fdca20a0-aeb4-4caf-ba1b-4351eee42363"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#fdca20a0-aeb4-4caf-ba1b-4351eee42363)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">What</span>
          </span>{' '}
          We Do
        </h2>
        <p className="text-base text-gray-700 md:text-lg">
          We'll take care of the application while you focus on your business.
        </p>
      </div>
      <div className="grid gap-8 row-gap-10 sm:mx-auto lg:max-w-full lg:grid-cols-3">
        <div className="flex flex-col sm:flex-row">
          <div className="sm:mr-4 w-fit mx-auto">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-slate-200">
              <svg
                className="w-12 h-12 text-fuchsia-700"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </div>
          <div>
            <h6 className="mb-2 font-semibold leading-5 text-lg lg:text-base text-fuchsia-700">Web development</h6>
            <p className="mb-3 text-sm text-gray-900">
              Our development services cover every area of your project, from design and testing through deployment and ongoing maintenance.
            </p>
            <ul className="mb-4 -ml-1 space-y-2">
              <li className="flex items-start justify-center sm:justify-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-fuchsia-700"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                Responsiveness
              </li>
              <li className="flex items-start justify-center sm:justify-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-fuchsia-700"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                Accessibility
              </li>
              <li className="flex items-start justify-center sm:justify-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-fuchsia-700"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                Security
              </li>
            </ul>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-fuchsia-700 hover:text-fuchsia-300"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="sm:mr-4 w-fit mx-auto sm:mx-0">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-slate-200">
              <svg
                className="w-12 h-12 text-fuchsia-700"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </div>
          <div>
            <h6 className="mb-2 font-semibold leading-5 text-lg text-fuchsia-700 lg:text-base">Custom app development</h6>
            <p className="mb-3 text-sm text-gray-900">
              We are very good at understanding your business processes and needs.
            </p>
            <ul className="mb-4 -ml-1 space-y-2 ml-">
              <li className="flex items-start justify-center sm:justify-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-fuchsia-700"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                ReactJS
              </li>
              <li className="flex items-start justify-center sm:justify-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-fuchsia-700"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                Vue.js
              </li>
              <li className="flex items-start justify-center sm:justify-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-fuchsia-700"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                Django
              </li>
            </ul>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-fuchsia-700 hover:text-fuchsia-300"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row">
          <div className="sm:mr-4 w-fit mx-auto">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-slate-200">
              <svg
                className="w-12 h-12 text-fuchsia-700"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </div>
          </div>
          <div>
            <h6 className="mb-2 font-semibold leading-5 text-lg text-fuchsia-700 lg:text-base">Product Design (UX/UI)</h6>
            <p className="mb-3 text-sm text-gray-900">
              We design your product, always with your target audience at the center of every iteration or feature you wish to add.
            </p>
            <ul className="mb-4 -ml-1 space-y-2 ml-">
              <li className="flex items-start justify-center sm:justify-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-fuchsia-700"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                Figma
              </li>
              <li className="flex items-start justify-center sm:justify-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-fuchsia-700"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                Wireframes
              </li>
              <li className="flex items-start justify-center sm:justify-start">
                <span className="mr-1">
                  <svg
                    className="w-5 h-5 mt-px text-fuchsia-700"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </span>
                MVP
              </li>
            </ul>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-fuchsia-700 hover:text-fuchsia-300"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
