import utils from 'services/utils';

export default function ProjectTracker() {
  function goToProjectTracker() {
    const url = 'https://project-tracker-dionisggr.vercel.app'

    utils.openInNewTab(url);
  };

  return (
    <section className="project-tracker px-4 py-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-16 pt-16" id='who-we-are'>
      <div>
        <p className="block w-fit m-auto px-10 mb-4 py-1 text-s font-semibold tracking-wider uppercase rounded-full text-white bg-fuchsia-700">
          Brand new
        </p>
      </div>
      <div className="p-8 rounded shadow-xl sm:p-16 cursor-pointer" onClick={goToProjectTracker}>
        <div className="flex flex-col lg:flex-row">
          <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
            <h2 className="font-sans text-3xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Check out our
              <br className="hidden md:block" />
              <span className="inline-block text-fuchsia-700">
                ProjectTracker!
              </span>
            </h2>
          </div>
          <div className="lg:w-1/2">
            <p className="mb-4 text-base text-gray-700">
              We're always trying to create our own in-house applications to manage our client services.
              Check out our brand new{' '}
              <span className="inline-block text-fuchsia-700 font-semibold">
                ProjectTracker
              </span>
              {' '}that keeps our clients always in the loop!
            </p>
            <a
              href="https://project-tracker-dionisggr.vercel.app"
              target="_blank"
              rel="noreferrer"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-fuchsia-700 hover:text-fuchsia-300"
            >
              Take me there!
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
