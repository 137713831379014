export default function HeroSection() {
  return (
    <section className="hero flex flex-wrap xl:flex-nowrap justify-center items-center relative pt-12 mx-auto lg:py-24 px-4 md:px-8 xl:px-20 sm:max-w-xl md:max-w-full md:max-w-[1500px]">
      <div className="max-w-xl mx-auto lg:max-w-screen-xl lg:m-0">
        <div className="mx-6 mb-16 lg:max-w-lg xl:mb-0">
          <div className="max-w-xl mb-6">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              A trusted{' '}
              <span className="inline-block text-fuchsia-700">
                web development
              </span>
              <br className="hidden md:block" />
              and{' '}
              <span className="inline-block text-fuchsia-700">
                business application
              </span>
              {' '}partner.
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              We help bring to life your web app concepts and make them a reality while keeping you always in the loop!
            </p>
            <p className="text-base text-gray-700 md:text-lg pt-4">
              We are known for our outstanding development skills, but we are so much more.
              We are a true strategic partner in business.
            </p>
          </div>
          <div className="flex justify-center mr-12 items-center mt-8">
            <a
              href="#contact"
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-fuchsia-700 transition duration-200 rounded shadow-md hover:bg-fuchsia-700 hover:text-white"
            >
              Contact Us
            </a>
            <a
              href="#what-we-do"
              aria-label=""
              className="inline-flex items-center font-semibold transition-colors duration-200 text-fuchsia-700 hover:text-fuchsia-300"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
      <div className="flex overflow-hidden lg:bottom-12 lg:right-12 lg:items-end min-w-[50%]">
        <img
          src="https://kitwind.io/assets/kometa/full-browser.png"
          className="object-cover object-top w-full h-64 max-w-xl -mb-16 rounded shadow-2xl xl:ml-12 lg:-mb-24 xl:-mb-28 lg:h-full"
          alt="Hero"
        />
      </div>
    </section>
  );
};
