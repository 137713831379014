import { useState } from 'react';

export default function Privacy(props) {
  const [show, toggleShow] = useState(false);

  if (!show) return (
    <a href="#!" onClick={() => toggleShow(!show)}>Privacy Policy</a>
  );

  return (
    <article className='privacy flex flex-col justify-center fixed top-0 bottom-0 left-0 right-0 bg-gray-200 opacity-90'>
      <section className='content flex flex-col justify-between border w-24 h-5/6 mx-auto mb-12 py-4 w-8/12 text-black text-center border-black rounded-2xl'>
        <h3>PRIVACY POLICY</h3>
        <div className='button-wrapper border-t border-black'>
          <button
            type='button'
            onClick={() => toggleShow(false)}
            className='text-black border-2 border-black py-2 px-6 rounded-xl m-auto mt-4 block'
          >
            Back
          </button>
        </div>
      </section>
    </article>
  );
};
